/* 
general 
-------
*/

html {
  scroll-behavior: smooth;
  scroll-padding-top: 170px;
}

::-moz-selection { /* Code for Firefox */
  color: unset;
  background: unset;
}

::selection {
  color: unset;
  background: unset;
}

body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 16px;
  line-height: 1.6;
  color: #C0C6D8;
  overflow-x: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.mainLayout .ant-layout-header {
  background: #332211;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.bgDarkBrown {
  background: #332211;
}

.bgSand {
  background-color: #FBF2C0;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #332211;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 34px;
  margin: 0;
  color: #332211;
}

.titleHolder p {
  font-size: 16px;
  color: #332211;
  margin: 1em;
}

/* 
title holder even
------------
*/
.titleHolderEven {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
}

.titleHolderEven:after {
  transform: translateX(-50%);
  content: '';
  background: #BC8034;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 50px;
}

.titleHolderEven h2 {
  font-size: 34px;
  margin: 0;
  color: #FBF2C0;
}

.titleHolderEven p {
  font-size: 16px;
  color: #FBF2C0;
  margin: 1em;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logo .svg_logo {
  animation-name: spin;
  animation-delay: 7s;
  animation-duration: 15s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.645,.045,.1,1); 
}

.header .logo .logo_text {
  margin-left: 1em;
}

.ant-anchor-link .link-title {
  margin-bottom: 0 !important;
  margin-top: 0.15em;;
  font-size: 0.9em;
  color: #BC8034;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link-active .link-title {
  font-size: larger;
  color: #FC814A;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link:hover .link-title {
  font-size: larger;
  color: #FC814A;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link .ant-btn{
  padding: 0 !important;
  line-height: normal;
  height: auto;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link .ant-btn.ant-btn:not([disabled]){
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.ant-anchor-link svg {
  color: #BC8034;
  width: 1.75em;
  height: 1.75em;
  margin-top: 5px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link:hover svg {
  color: #FC814A;
  font-size: larger;
  margin-top: 0px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-link-active svg {
  color: #FC814A;
  font-size: larger;
  margin-top: 0px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-anchor-wrapper {
  background: none;
  margin: 0;
  padding: 0;
  overflow: inherit;
  width: auto;
  height: auto;
}

.ant-anchor {
  display: flex;
  padding: 0;
}

.ant-anchor-ink {
  display: none;
}

.ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #9236d3;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #9236d3;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #9236d3;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

.header .ant-btn:not([disabled]) {
  background-color: transparent;
  border-width: 0px;
  color: #9236d3;
}

.ant-drawer-wrapper-body {
  background-color: #040404;
}

.ant-drawer-wrapper-body .ant-anchor-link-title {
  color: #d14dd1;
}

.ant-drawer-wrapper-body .ant-anchor-link-title-active {
  color: #9236d3;
}

.ant-drawer-wrapper-body .ant-anchor-ink::before {
  background: linear-gradient(to bottom, #9236d3 , #d14dd1, #9236d3);
}

.ant-drawer-wrapper-body .ant-anchor-ink-ball {
  background-color: #040404;
  border: 2px solid #9236d3;
}

/* 
hero
---- 
*/
.heroBlock {
  background-color: #FBF2C0;
  background-position: 50% 100%;
  background-size: cover;
  height: 100vh;
}

.heroBlock .container-fluid {
  display: block !important;
  color: #332211;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.ant-carousel .slick-dots li {
  background-color: #9D91A3;
  border-radius: 5px;
}

.heroBlock .slick-dots li button:hover {
  background: #FC814A !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #FC814A !important;
}

.heroBlock .contentWrapper {
  overflow: hidden;
  padding-top: 170px; /* Header  */
}

.heroBlock .content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin-top: 15%;
  float: left;
}

.heroBlock .image {
  width: 35%;
  height: 35%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  float: right;
}

.heroBlock .image img {
  border-width: 5px;
  border-radius: 50px;
}

.heroBlock h3 {
  font-size: 34px;
  line-height: 1.2;
  margin: 0 0 30px;
  color: #332211;
}

.heroBlock p {
  margin: 0 0 30px;
  font-size: 18px;
  line-height: 2;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .ant-anchor-link {
  height: auto !important;
}

.heroBlock .ant-anchor-link svg {
  color: #BC8034;
  width: 2.3em;
  height: 2.3em;
  margin-top: 5px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.heroBlock .ant-anchor-link .link-title {
  font-size: 1.3em;
  color: #BC8034;
}

.heroBlock .ant-anchor-link:hover svg {
  color: #FC814A;
  font-size: larger;
  margin-top: 0px;
}

.heroBlock .ant-anchor-link:hover .link-title {
  color: #FC814A;
  font-size: x-large;
}

/* 
block
----- 
*/
.block {
  padding: 2em 0;
  border-bottom: 0px;
}

/*
projects
--------
*/
.featureBlock {
  height: calc(100vh - 170px); /* 100% - header */
  justify-content: center;
  align-content: center;
  flex-direction: column;
  vertical-align: middle;
  overflow: hidden;
}

.featureBlock .container-fluid {
  color: #332211;
}

.featureBlock .ant-card-meta {
  display: inline;
}

.featureBlock .ant-card-body {
  padding: 1em;
}

.featureBlock .content {
  display: flow;
  align-content: center;
  height: 55vh
}

.featureBlock .contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featureBlock .slick-slider .anticon {
  font-size: larger;
  color: #FBF2C0;
}

.featureBlock .slick-slider .anticon:hover {
  color: #FC814A;
}

.featureBlock .ant-image {
  justify-content: center;
  overflow: hidden;
}

.featureBlock .ant-image-img {
  object-fit: fill;
}

.featureBlock .ant-card-meta {
  width: 100% !important;
  height: 15% !important;
}

.featureBlock .content .ant-row {
  justify-content: center;
}

.project-card {
  vertical-align: middle;
  display: inline-block;
  width: 25%;
  background-color: transparent;
  border-width: 0px;
  border-radius: 3px;
  margin: 3em;
  margin-bottom: 0;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

.project-card:hover {
  background-color: #FBF2C0;
  border-width: 0px;
  border-radius: 3px;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

.ant-card-meta-title {
  color: #FBF2C0;
  font-size: 18px;
  text-align: center;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

.ant-card-meta-description {
  color: #FBF2C0;
  text-align: center;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

.project-card:hover .ant-card-meta-title {
  color: #332211;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

.project-card:hover .ant-card-meta-description {
  color: #332211;
  transition: all .5s cubic-bezier(.645,.045,.355,1);
}

/* 
contact
-------
*/
.contactBlock {
  height: calc(100vh - 170px - 215.17px); /* Page height - header - footer */
}

.contactBlock .btnHolder {
  margin: 3em;
  margin-bottom: 6em;
  display: flex;
  justify-content: center;
}

.contactBlock .ant-btn {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.contactBlock .ant-anchor svg {
  background-color: transparent;
  color: #BC8034;
  width: 2.3em;
  height: 2.3em;
  margin-top: 5px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.contactBlock .ant-anchor .link-title {
  font-size: 1.3em;
  color: #BC8034;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.contactBlock .ant-anchor:hover svg{
  color: #FC814A;
  font-size: larger;
  margin-top: 0px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.contactBlock .ant-anchor:hover .link-title {
  color: #FC814A;
  font-size: x-large;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #332211 !important;
  color: #DDDDDD !important;
  padding: 1em 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  margin: 0;
}

.footer .logo .svg_logo {
  animation-name: spin;
  animation-delay: 29s;
  animation-duration: 20s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.645,.045,.1,1); 
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
  margin: 0.5em;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #BC8034;
}

.footer .socials a:hover {
  color: #FC814A;
}

.footer .copyright a {
  color: #DDDDDD;
}

.footer .copyright a:hover {
  color: #FC814A;
}

.footer .goTop {
  color: #BC8034;
}

.footer .goTop:hover {
  color: #FC814A;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

.mobileHeader {
  width: 0;
  height: 0;
}

/* 
keyframes
----------
*/

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-3600deg);
  }
}

/* 
responsive
----------
*/

/*@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}*/

@media only screen and (max-width: 1023px) {
  /*
  generic
  -------
  */
  .mobileVisible {
    display: block;
    padding: 0 2em;
  }
  
  .mobileHidden {
    display: none;
  }

  .ant-layout-footer {
    padding: 0 !important;
  }

  .container-fluid {
    padding: 0;
  }

  .contactBlock {
    width: 100vw;
    height: 100vh;
    background-color: white;
  }

  .mobileVisible p {
    color: #332211;
    font-size: 1.4em;
    text-align: justify;
    text-justify: inter-word;
  }

  /*
  header
  ------
  */
  
  
  
  /*
  .container-fluid {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .header {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }

  .header .logo .svg_logo {
    height: 22vw;
  }

  .header .logo .logo_text {
    margin-left: 0.2em;
    height: 40vw;
  }*/

  /*
  hero
  ----
  *//*
  .heroBlock .contentWrapper {
    padding-top: 30vh;
  }

  .heroBlock .content {
    margin-top: 0.5em;
    max-width: 100%;
    text-align: center;
  }

  .heroBlock h3 {
    font-size: 26px;
    margin-bottom: 0.5em;
  }

  .heroBlock p {
    font-size: 13.8px;
    margin-bottom: 0.5em;
  }

  .heroBlock .image {
    width: 0;
    height: 0;
    display: none;
  }

  .heroBlock .ant-anchor {
    justify-content: center;
  }

  .heroBlock .ant-anchor-link {
    padding-top: 0.3em;
  }

  .heroBlock .ant-anchor-link svg {
    width: 2.1em;
    height: 2.1em;
  }
  
  .heroBlock .ant-anchor-link .link-title {
    font-size: 1.1em;
  }

  .heroBlock .slick-dots {
    margin-bottom: 0;
  }*/

  /*
  projects
  --------
  *//*
  .featureBlock {
    padding: 0.3em;
    height: 71vh;
  }

  .featureBlock h2 {
    font-size: 26px;
  }

  .titleHolderEven {
    padding-bottom: 0.3em;
  }

  .titleHolderEven p {
    margin: 0.2em;
  }

  .featureBlock .content {
    width: calc(100vw - 0.6em);
    height: calc(54vh - 0.6em);
  }*/
}